import React from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  // Filter routes to exclude "View Mikrotik" and "View Profile" from sidebar
  const filteredRoutes = routes.filter(
    (route) =>
      route.name !== "View Mikrotik" &&
      route.name !== "Mikrotik Profile Detail" &&
      route.name !== "Sites Profile Detail" &&
      route.name !== "View Site"
  );

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[35px] mt-[30px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          MikroTik Apps
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-1">
        {/* Render Links component with filtered routes */}
        <Links routes={filteredRoutes} />
      </ul>
    </div>
  );
};

export default Sidebar;
