import { TableSite } from "./components/TableSite";

const Sites = () => {
  return (
    <div>
      <TableSite />
    </div>
  );
};

export default Sites;
