import { TableSecret } from "./components/TableSecret";

const ViewDetail = () => {
  return (
    <div>
      <TableSecret />
    </div>
  );
};

export default ViewDetail;
