import { TableMikrotik } from "./components/TableMikrotik";

const Mikrotik = () => {
  return (
    <div>
      <TableMikrotik />
    </div>
  );
};

export default Mikrotik;
