import { TableAccount } from "./components/TableAccount";

const Mikrotik = () => {
  return (
    <div>
      <TableAccount />
    </div>
  );
};

export default Mikrotik;
