import TableStatistic from "./components/TableStatistic";

const ViewDetail = () => {
  return (
    <div>
      <TableStatistic />
    </div>
  );
};

export default ViewDetail;
